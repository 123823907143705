import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AdminActions, AlertActions, ProjectsActions } from 'redux/actions';
import { Layout } from 'components';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Radio,
  Spin,
  Switch,
  Table,
} from 'antd';
import { Container, H2, PrimaryButton, Modal, H3 } from 'utils';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { adminServices } from 'services';
import FormItem from 'antd/lib/form/FormItem';
import { UploadImage } from 'components';
import {
  CloseOutlined,
  CheckOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { projectsConstants } from 'redux/actions/types';
import './style.css';

const TableWrapper = styled.div`
  padding: 50px 0;
`;

const perPage = 20;
export class AllCategory extends Component {
  static propTypes = {
    projects: PropTypes.any.isRequired,
    getAllProjects: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const { getProjectAdminCategories, adminCategories } = this.props;
    adminCategories.length === 0 && getProjectAdminCategories();
  }
  state = {
    status: null,
    page: 1,
    showModal: false,
    editingKey: '',
    editingName: '',
    loading: false,
    categoryImage: '',
    catToEdit: {},
  };
  handelButton = () => {
    this.setState({ showModal: true });
    document.body.style.overflowY = 'hidden';
  };
  onAddModal = async (v) => {
    const isEdit = this.state.catToEdit.category;
    const { categoryImage } = this.state;
    const formData = new FormData();
    formData.append('name', v.name);
    formData.append('visible', v.visible);
    // formData.append('new_item', v.new_item);
    formData.append('category_title', v.category_title);

    if (isEdit) {
      if (categoryImage?.[0] && categoryImage[0].thumbUrl) {
        formData.append(
          'category_image',
          categoryImage[0].originFileObj,
          categoryImage[0].name
        );
      } else if (this.state.categoryImage == null) {
        formData.append('category_image', null);
      } else {
        formData.delete('category_image');
      }
    } else {
      categoryImage?.[0] &&
        formData.append('category_image', categoryImage[0].originFileObj);
    }

    const { adminCategories: categories } = this.props;
    this.setState({ showModal: false, loading: true });
    try {
      if (!isEdit) {
        const res = await adminServices.adminAddCategory(formData);
        this.props.setProjectCategories([
          ...categories,
          {
            value: res.projects.id,
            category: res.projects.name,
            visible: res.projects.visible,
            // new_item: res.projects.new_item,
            image: res.projects.image_url,
            category_title: res.projects.category_title,
          },
        ]);
      } else {
        const updatedCat = await adminServices.updateCategory(
          this.state.catToEdit.value,
          formData
        );

        this.props.setProjectCategories(
          categories.map((cat) => {
            if (cat.value === updatedCat.id) {
              return {
                ...cat,
                visible: updatedCat.visible,
                // new_item: updatedCat.new_item,
                image: updatedCat.image_url,
                category: updatedCat.name,
                category_title: updatedCat.category_title,
              };
            }
            return cat;
          })
        );
      }
      document.body.style.overflowY = 'unset';
    } catch (err) {
      console.log(err);
    }
    this.setState({ loading: false, catToEdit: {}, categoryImage: '' });
  };
  handelCloseModal = () => {
    this.setState({ showModal: false, catToEdit: {}, categoryImage: '' });
    document.body.style.overflowY = 'unset';
  };
  saveEdit = async (key) => {
    const { adminCategories: categories, getProjectAdminCategories } =
      this.props;
    const newName = this.state.editingName;
    const categoryToEdit = categories.find(
      (category) => category.value === key
    );
    this.setState({ loading: true });

    try {
      await adminServices.saveEditCategory(this.state.editingKey, newName);
      categoryToEdit.category = newName;
      getProjectAdminCategories();
      AlertActions.success('Category updated successfully!', 'Success');
    } catch (error) {
      AlertActions.error(error, 'Fail');
    } finally {
      this.setState({ loading: false });
    }
    this.setState({ editingKey: '', editingName: '' });
  };

  handleDeleteCategory = async (id) => {
    const { adminCategories: categories, getProjectAdminCategories } =
      this.props;
    try {
      this.setState({ loading: true });
      await adminServices.deleteCategory(id);
      getProjectAdminCategories();
      AlertActions.success('Category Deleted successfully!', 'Success');
      const updatedCategories = categories.filter(
        (category) => category.value !== id
      );
      this.props.setProjectCategories(updatedCategories);
    } catch (error) {
      AlertActions.error(error.toString(), 'Fail');
    } finally {
      this.setState({ loading: false });
    }
  };

  handleToggleCategory = async (id, val) => {
    this.setState({ loading: true });
    const { adminCategories: categories } = this.props;
    const categoryToUpdate = categories.find(
      (category) => category.value === id
    );

    try {
      this.setState({ loading: true });
      await adminServices.toggleCategory(id, val, categoryToUpdate.category);
      categoryToUpdate.visible = val;
    } catch (error) {
      AlertActions.error(error.toString(), 'Fail');
    } finally {
      this.setState({ loading: false });
    }
  };

  setCategoryImage = (image) =>
    this.setState({ categoryImage: image.length ? image : null });

  render() {
    const { loading, adminCategories: categories } = this.props;
    const { catToEdit } = this.state;

    const isEdit = catToEdit.category;
    const columns = [
      {
        title: 'Ausweis',
        dataIndex: 'Ausweis',
        key: 'view',
        render: (text, record) => <span>{record.value}</span>,
      },
      {
        title: 'Kategoriename',
        dataIndex: 'category',
        key: 'category',
        render: (text, record) => {
          return this.state.editingKey === record.value ? (
            <EditableInput
              value={this.state.editingName}
              onChange={(e) => this.setState({ editingName: e.target.value })}
              onCancel={() =>
                this.setState({ editingKey: '', editingName: '' })
              }
              onSubmit={() => this.saveEdit(record.value)}
            />
          ) : (
            <span>{record.category}</span>
          );
        },
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (_, record) => {
          return (
            <span>
              <Button
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                  marginLeft: '-8px',
                }}
                icon={<EditOutlined />}
                onClick={() => {
                  // this.setState({
                  //   editingKey: record.value,
                  //   editingName: record.category,
                  // });

                  const catToEdit = categories.find(
                    (cat) => cat.value === record.value
                  );

                  this.setState({ catToEdit });
                  this.handelButton();
                }}
              ></Button>{' '}
              <Button
                style={{ border: 'none', backgroundColor: 'transparent' }}
                onClick={() => this.handleDeleteCategory(record.value)}
                icon={<DeleteOutlined />}
              ></Button>
            </span>
          );
        },
      },

      {
        title: 'Visible',
        key: 'visible',
        render: (_, record) => {
          return (
            <Switch
              onChange={(val) => this.handleToggleCategory(record.value, val)}
              checked={record.visible}
            />
          );
        },
      },
    ];

    return (
      <Spin spinning={this.state.loading}>
        <Layout>
          <Container>
            <PrimaryButton
              className="mt-4"
              type="primary"
              minWidth="150px"
              onClick={() => this.handelButton()}
            >
              Hinzufügen
            </PrimaryButton>
            <TableWrapper>
              <Table
                columns={columns}
                dataSource={categories}
                loading={loading}
                pagination={{
                  total: categories?.length,
                  pageSize: perPage,
                  hideOnSinglePage: true,
                  current: this.state.page,
                  onChange: (page) => {
                    this.setState({ page });
                  },
                }}
              />
            </TableWrapper>
            <Modal
              showModal={this.state.showModal}
              handelCloseModal={this.handelCloseModal}
            >
              <H3>Kategorie</H3>
              <Form layout="vertical" onFinish={this.onAddModal}>
                <FormItem
                  normalize={(value) => {
                    return value.replace(/[^a-zA-Z0-9-_]/g, '');
                  }}
                  label="URL"
                  name="url"
                  initialValue={isEdit ? catToEdit.url.replace(/ /g, '_') : ''}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Title" />
                </FormItem>
                <FormItem
                  label="Title"
                  name="name"
                  initialValue={isEdit ? catToEdit.category : ''}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Title" />
                </FormItem>
                <FormItem
                  className="form-item-visible"
                  label="Visible"
                  name="visible"
                  valuePropName="checked"
                  initialValue={isEdit ? catToEdit.visible : true}
                >
                  <Checkbox />
                </FormItem>
                {/* <FormItem
                  className="form-item-visible"
                  label="Is New"
                  name="new_item"
                  valuePropName="checked"
                  initialValue={isEdit ? catToEdit.new_item : true}
                >
                  <Checkbox />
                </FormItem> */}
                <FormItem
                  className="form-item-visible"
                  label="Category"
                  name="category_title"
                  initialValue={isEdit && catToEdit.category_title}
                >
                  <Radio.Group>
                    <Radio value="new item">Is New</Radio>
                    <Radio value="category_2">Category 2</Radio>
                    <Radio value="category_3">Category 3</Radio>
                    <Radio value="category_4">Category 4</Radio>
                  </Radio.Group>
                </FormItem>
                <FormItem
                  className="form-item-visible"
                  label="Device View"
                  name="device"
                  initialValue={
                    isEdit ? catToEdit.device ?? 'desktop' : 'desktop'
                  }
                >
                  <Radio.Group>
                    <Radio value="mobile">Mobile</Radio>
                    <Radio value="desktop">Desktop</Radio>
                  </Radio.Group>
                </FormItem>
                <UploadImage
                  // required={true}
                  multiple={false}
                  label={<p>Vorschaubild </p>}
                  name={'categoryImage'}
                  setImage={this.setCategoryImage}
                  fileList={isEdit ? [catToEdit.image] : []}
                />
                <PrimaryButton type="primary" minWidth="100px">
                  {isEdit ? 'Update' : 'Add'}
                </PrimaryButton>
              </Form>
            </Modal>
          </Container>
        </Layout>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  const { projects } = state.admin;
  const { adminCategories, loading, errors } = state.projects;
  return {
    projects,
    loading,
    adminCategories,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllProjects: (perPage, page, status = null) =>
    dispatch(AdminActions.getAllProjects(perPage, page, status)),
  getProjectAdminCategories: () =>
    dispatch(ProjectsActions.getProjectAdminCategories()),
  setProjectCategories: (categories) =>
    dispatch({
      type: projectsConstants.SET_CATEGORIES,
      payload: categories,
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(AllCategory);

const EditableInput = ({ value, onChange, onSubmit, onCancel }) => {
  return (
    <div style={{ display: 'flex' }}>
      <Input value={value} onChange={onChange} style={{ maxWidth: '250px' }} />
      <Button type="link" icon={<CheckOutlined />} onClick={onSubmit} />
      <Button type="link" icon={<CloseOutlined />} onClick={onCancel} />
    </div>
  );
};
